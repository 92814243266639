import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { OrderRead, OrderWrite } from '@/data/commerce/order.graphql';
import { Company, MutationOrderWriteArgs, Order, QueryOrderReadArgs, Staff } from '@/types/schema';
import { addDays } from 'date-fns';
import { isEmpty, omit, pick } from 'lodash-es';
import { commercePropertiesToOmit, lineItemPropertiesToOmit } from '../../invoices/actions/invoiceUtils';

export default async function makeOrderCopy( order: Order, company?: Company, staff?: Staff ) {
	const { orderRead } = await queryGraphQL<QueryOrderReadArgs>( {
		query    : OrderRead,
		variables: { id: order.id },
	} );
	
	const lineItems = orderRead.lineItems;
	const agreements = orderRead.agreements;
	const prices = orderRead.prices;
	
	const newLineItems: any[] = lineItems.map( ( lineItem, index ) => ( {
		name          : lineItem.name,
		image         : lineItem.image,
		unit          : lineItem.unit || null,
		description   : lineItem.description,
		quantity      : +lineItem.quantity || 1,
		cost          : +lineItem.cost || 0,
		price         : +lineItem.price || 0,
		originalPrice : +lineItem.originalPrice,
		cashDiscount  : +lineItem.cashDiscount,
		tax           : lineItem.tax,
		sequence      : index,
		code          : lineItem.code,
		orderTax      : lineItem.orderTax,
		uom           : lineItem.uom?.id || null,
		item          : lineItem.item?.id ?? undefined,
		category      : lineItem.category?.id ?? undefined,
		metadata      : omit( lineItem.metadata, lineItemPropertiesToOmit ),
		modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
		prices        : lineItem.prices?.map( ( price ) => ( {
			...pick( price, [ 'name', 'isPercent', 'value', 'quantity', 'externalId' ] ),
			metadata: price.metadata || {},
			quantity: price.quantity !== undefined ? price.quantity : 1,
		} ) ),
	} ) );
	
	const { orderWrite } = await mutateGraphQL<MutationOrderWriteArgs>( {
		mutation : OrderWrite,
		variables: {
			id          : null,
			customNumber: Boolean( company?.metadata?.customNumber ),
			method      : 'Copied Order',
			input       : {
				...pick( orderRead, [
					'type',
					'notes',
					'po',
					'terms',
					'standing',
					'standingDue',
					'standingData',
					'duePeriod',
					'serviceType',
					'attachments',
					'dateSent',
					'taxPercent',
				] ),
				lineItems      : newLineItems,
				gateway        : null,
				standingDate   : new Date(),
				dueDate        : addDays( new Date(), orderRead.company?.metadata?.dueDate || orderRead.company?.metadata?.serviceDateDue || 0 ),
				serviceDate    : new Date(),
				policy         : orderRead.policy?.id || null,
				staff          : staff.id || orderRead.staff?.id || null,
				client         : orderRead.client?.id || null,
				companyLocation: orderRead.companyLocation?.id || null,
				shippingAddress: orderRead.shippingAddress?.id || null,
				clientAddress  : orderRead.clientAddress?.id || null,
				metadata       : {
					...omit( orderRead.metadata, commercePropertiesToOmit ),
					enableCashDiscount: company?.metadata?.cashDiscount > 0,
					signatureLine     : company?.metadata?.signatureLine,
				},
				agreements     : agreements?.map( ( agreement ) => ( {
					...pick( agreement, [
						'title',
						'body',
						'requireSignature',
						'expiration',
					] ),
					company: orderRead.company.id,
				} ) ),
				prices         : !isEmpty( prices )
					? prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
						.map( ( price ) =>
							pick( price, [
								'name',
								'isPercent',
								'value',
								'quantity',
								'metadata' ] ) )
					: [],
			},
		},
	} );
	return orderWrite.id;
}
